.c-list {
  display: flex;
  flex-direction: column;
}

.inner-list {
  margin: 10px 0 10px 25px;
}

.c-list__header.table_header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 56px;
  padding-left: 10px;
  font-weight: bold;
  font-size: 13px;
  color: #4b4b4b;
  background: #f1f1f1;
  border: 1px solid #d1d1d1;
  border-radius: 4px 4px 0 0;
}

.c-list__header-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-basis: 150px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: visible;
  height: 100%;
}

.c-list__column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-basis: 150px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
}

.c-list__header-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-wrap;
  overflow: visible;
  height: 100%;
  position: relative;
}

.c-list__arrows {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 10px;
  height: 13px;
}

.c-list__arrows .selected {
  pointer-events: none;
  opacity: 0.5;
}

.delete-arrow {
  display: none;
}

.biggest-col {
  max-width: 350px;
}

.grouped-col {
  min-width: 200px;
}

.big-col {
  width: 100%;
}

.custom-col {
  max-width: 180px;
}

.medium-col {
  max-width: 150px;
}

.small-col {
  max-width: 120px;
}

.tiny-col {
  max-width: 25px;
}
.xs-col {
  max-width: 80px;
}
.l-col {
  max-width: 250px;
  min-width: 200px;
}
.smallest-col {
  max-width: 55px;
}
.avatar-col {
  max-width: 40px;
  min-width: 40px;
  padding: 0;
  margin-right: 16px;
}

.email-col {
  min-width: 55px;
}

.c-list__status span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  border-radius: 4px;
  font-size: 12px;
  text-transform: capitalize;
  padding: 0 5px;
}

.c-list__status svg {
  cursor: pointer;
}

.c-list__options-column {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: transparent;
  transition: 0.3s;
}

.c-list__options-column__svg:hover {
  background: #e5dace;
}

.list__no-item--border {
  min-height: 50px;
  padding: 17px;
  font-size: 13px;
  color: #4b4b4b;
  border-top: 1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
}
.shift_up-header > span {
  margin-bottom: 35px;
}

.fixed-wrapper_c-list__header {
  position: absolute;
  width: 100%;
  height: 40px;
  color: #4b4b4b;
  background: #fafafa;
  z-index: 1;
}
.fixed-wrapper_c-list__content {
  padding-top: 56px;
}

.page-container_for-scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 36px 20px;
}

.scrollbars > div {
  margin-bottom: -5px !important;
}

.custom_scrollbar-container {
  overflow: visible !important;
}

.custom_scrollbar-container > div {
  overflow: hidden !important;
  margin-right: 0 !important;
}

.custom_scrollbar-container > div:nth-child(1) {
  margin-bottom: 1px !important;
}

.highlight-item {
  background: rgba(255, 148, 9, 0.384) !important;
}

.highlight-item:hover {
  background: rgba(255, 148, 9, 0.384) !important;
}

.second-highlight-item {
  background: rgba(253, 187, 101, 0.233) !important;
}

.second-highlight-item:hover {
  background: rgba(253, 187, 101, 0.233) !important;
}

.dismissed {
  opacity: 0.3;
}

.action-col {
  display: flex;
  justify-content: flex-end;
  flex-basis: 100px;
  padding: 0 10px;
  max-width: 50px;
}

.manager-tooltip-title h3 {
  margin: 0;
}

.cell-manager-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*noinspection CssInvalidPropertyValue*/
  width: -webkit-fill-available;
}
.list__is_fetching {
  opacity: 0.6;
  pointer-events: none;
}
.without_padding {
  padding: 0;
}
