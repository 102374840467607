.version {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #dfdfdf;
}

.content-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.logo-wrapper {
  background: #4b4b4b;
}

.content-wrapper form {
  width: 70%;
  height: 70%;
  max-width: 400px;
}
