.profile-block__tabs {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  height: 50px;
}

.profile-block__tabs a {
  text-decoration: none;
}
