.header-cont {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 50px;
  border: 1px solid #e8e8e8;
}

.main-part {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: center;
  padding-left: 20px;
}

.user-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin: 0 10px;
  user-select: none;
}

.user-photo > .c-list-logo {
  width: 100%;
  height: 100%;
}
