.employeeCVsPage {
  margin-top: 30px;
}

.employeeCVsPage .c-list__item-cont {
  cursor: inherit;
}

.employeeCVsPage .fixed-wrapper_c-list__content {
  padding-top: 0;
}

.employeeCVsPage .c-list__item-cont.archived .operation {
  pointer-events: none;
}

/* Except cell CellButtonList otherwise actions menu is not clickable */
.employeeCVsPage
  .c-list__item-cont.archived
  .c-list__item-column:not(.c-list__options_overflow_unset) {
  opacity: 0.5;
}

.employeeCVsPage .smallest-col.cv-status-col {
  max-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.employeeCVsPage .cv-file > i {
  margin-right: 16px;
}

.employeeCVsPage .cv-file a {
  opacity: 0;
}

.employeeCVsPage .cv-file:hover a {
  opacity: 1;
}

.employeeCVsPage .operation i {
  cursor: pointer;
}

.add_new_CV_dialog .form-control > div > div {
  background: #fff;
  padding: 9px 14px;
}
.add_new_CV_dialog .multi-value-suggestbox__container {
  height: auto;
}

.add_new_CV_dialog .dialog_title {
  padding-bottom: 0;
}

.add_new_CV_dialog .dialog_content {
  border: none;
  padding: 8px 24px 24px;
}

.add_new_CV_dialog .dialog_actions .cv_modal_action_buttons {
  padding: 16px;
  width: 100%;
}

.add_new_CV_dialog .submit_btn {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 6px 16px;
  color: white;
  background: #ef7530;
}

.add_new_CV_dialog .submit_btn:hover {
  background: #ef7530;
}

.add_new_CV_dialog .cancel_btn {
  font-weight: 400;
  padding: 6px 16px;
  color: black;
}
