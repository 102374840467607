.menu_container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  box-sizing: border-box;
  border-left: 1px solid #e8e8e8;
  cursor: pointer;
  margin-right: 5px;
}

.menu_menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  top: 48px;
  right: 1px;
  border: 1px solid #e8e8e8;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.25);
  transition: 0.2s;
  visibility: hidden;
  z-index: 999;
}

.menu_option {
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 0 0 20px;
  transition: 0.3s;
  cursor: pointer;
  background: white;
}

.menu_option:hover {
  background: #e8e8e8;
}

.menu_menu_expanded {
  opacity: 1;
  min-height: 40px;
  visibility: visible;
}

.menu_arrow-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
  height: 50%;
  width: 20px;
  transition: 0.3s;
  transform: rotate(0);
}

.menu_arrow-icon_expanded {
  transform: rotate(180deg);
}
