.preloader_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background: rgba(57, 57, 57, 0.3);
}
.preloader_wrapper > div {
  width: 60px;
  margin: 45vh auto;
}
.pointer-events {
  pointer-events: none !important;
}
