.font-14 {
  font-size: 14px;
}

input::placeholder {
  transition: color 0.35s;
}

input:focus::placeholder,
textarea:focus::placeholder {
  color: transparent;
}

.app {
  display: flex;
  width: 100%;
  height: 100vh;
  font-size: 14px;
}

.page {
  display: flex;
  height: 100%;
  width: 100%;
  min-width: 1280px;
  overflow: hidden;
}

.page-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: calc(100vw - 225px);
  height: 100vh;
}

.scrollbars > div {
  overflow: hidden !important;
}

.checkbox-cont-list-item {
  position: relative;
  width: 100%;
  height: 16px;
  margin: 0 15px 0 0;
}

.checkbox-cont-list-header input,
.checkbox-cont-list-item input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1px solid #bdbdbd;
  cursor: pointer;
  transition: 0.3s;
}

.grouped-checkbox {
  color: #f26939 !important;
}

.checkbox-cont-list-item:hover input ~ .checkbox,
.checkbox-cont-list-item:hover input ~ .checkbox,
.checkbox-cont-list-item:hover input ~ .configuration-option__checkbox {
  background-color: #f8f8f8;
}

.checkbox-cont-list-item input:checked ~ .checkbox,
.checkbox-cont-list-item input:checked ~ .checkbox,
.checkbox-cont-list-item
  input:checked
  ~ .configuration-option__checkbox__container
  > .configuration-option__checkbox {
  background-color: #f26939;
  border: 1px solid #f26939;
}

.checkbox-disabled input:checked ~ .configuration-option__checkbox {
  background-color: rgb(189, 189, 189) !important;
  border: 1px solid rgb(189, 189, 189) !important;
}

.checkbox:after,
.configuration-option__checkbox:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-cont-list-item input:checked ~ .checkbox:after,
.checkbox-cont-list-item input:checked ~ .checkbox:after,
.checkbox-cont-list-item
  input:checked
  ~ .configuration-option__checkbox__container
  > .configuration-option__checkbox:after {
  display: block;
}

.checkbox-cont-list-item .checkbox:after,
.checkbox-cont-list-item .checkbox:after,
.checkbox-cont-list-item .configuration-option__checkbox:after {
  left: 30%;
  top: 10%;
  width: 3px;
  height: 6px;
  border-style: solid;
  border-color: white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.helper-text {
  position: absolute;
  top: 55px;
  margin: 0 !important;
  color: orangered !important;
  cursor: default;
}

.clear-svg {
  position: absolute;
  bottom: 25px;
  right: 18%;
  transform: scale(0.6);
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

/* Badge */
.c-list-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  margin: 0 10px 0 0 !important;
  width: 24px;
  height: 24px;
  color: white;
  font-size: 12px;
}

.c-list-logo--badge {
  font-size: 12px;
  color: #ffffff;
}

.c-list__badges .c-list-logo {
  margin: 0 0 0 -5px;
}

/* Pages default style */
.page-cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 50px);
  overflow: auto;
}

.page-cont::-webkit-scrollbar {
  display: none;
}

.back-button {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  width: max-content;
  height: max-content;
  line-height: 14px;
  margin: 0 10px 0 0;
}

.back-button svg {
  margin: 0 10px 0 0;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 35px;
  transition: 0.3s;
  cursor: pointer;
  user-select: none;
}

.empty-btn {
  border: none;
  background: none;
}

input::-ms-clear {
  display: none;
}

.cursor_auto {
  cursor: auto !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.grouped-select_wrapper > div > div > label {
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

.MuiAutocomplete-popper {
  min-width: 200px;
}

button[disabled] {
  cursor: default;
}

.select-wrapper label {
  top: 4px;
  transform: translate(0, 1.5px) scale(0.75);
  transform-origin: top left;
}

.next:not(.cancel) {
  color: white;
  background: #f26939;
}

.next:not(.cancel):hover {
  background: #d95e33;
}

.btn-disabled {
  cursor: default !important;
  background-color: #e4e4e4 !important;
}

.formik_warnings li {
  color: rgb(245, 187, 71);
}

i.action_icon {
  cursor: pointer;
}

/* old styles from EmployeeDetails Component */
:root {
  --light-green-color: #34cb85;
  --white-color: #fff;
}

.employee-info {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  margin-top: 5px;
}

.title {
  display: flex;
  align-items: flex-start;
  color: #828282;
  font-size: 12px;
  flex-shrink: 0;
  height: 30px;
}

/* todo: double-check it */
.MuiDialogActions-root {
  justify-content: flex-end !important;
}

.cv__file-name-width {
  width: 85% !important;
  white-space: nowrap;
  overflow: hidden;
}

.margin-left-5px {
  margin-left: 5px;
}

.MuiDataGrid-cell:focus-within,
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

pre {
  font-family: inherit;
  white-space: pre-wrap;
}
