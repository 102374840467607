.profile_info {
  padding: 30px 36px 0 36px;
}
.profile_info__tabs {
  padding: 0 36px 0 36px !important;
}
.profile_info .img_wrap {
  margin-right: 30px;
}
.profile_info > div:nth-child(2) {
  padding-top: 19px;
  border-top: 1px solid #d1d1d1;
}

.profile_info .img_wrap img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.employee_name {
  /*min-width: 200px;*/
}

.profile_info .employee_name p {
  font-weight: bold;
  font-size: 18px;
  margin: 5px 0;
}

.profile_info .employee_name span {
  font-size: 16px;
}

.profile_info__actions_wrap {
  display: flex;
  align-items: center;
  height: 35px;
}
.profile_info__actions_wrap button {
  margin: 6px 0 6px 10px !important;
}
