.dialog-width div div {
  width: 500px;
  overflow-y: visible;
}

.show-dialog-title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: normal;
  text-transform: uppercase;
}

.show-dialog-content {
  position: relative !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000000;
  margin-left: 10px;
  margin-right: 5px;
}
