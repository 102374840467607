.org_wrapper {
  padding: 0px 36px 36px 36px;
}
.org_title {
  font-weight: 600;
  font-size: 15px;
  line-height: 143%;
  letter-spacing: 0.17px;
  text-transform: uppercase;
  color: #222222;
}
.org_prolongation_title {
  font-weight: 600;
  font-size: 16px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #222222;
}
.org_prolongation_reason {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #4b4b4b;
}
.finished_status {
  background-color: #1cb18e99 !important;
  font-weight: 700;
  font-size: 13px !important;
  line-height: 160%;
  letter-spacing: 0.15px !important;
  color: #ffffff !important;
}
.in_progress_status {
  background-color: #c9e6f4 !important;
  color: #4b4b4b !important;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 160%;
  letter-spacing: 0.15px;
}
.failed_status {
  background-color: rgba(235, 82, 48, 0.5) !important;
  font-weight: 700;
  font-size: 13px !important;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: #ffffff !important;
}
.org_date {
  font-weight: 400;
  font-size: 16px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #222222;
  padding-left: 16px;
}
.probation_button {
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #ef7530;
  padding: 10px 26px;
  background-color: unset;
  border: 1px solid #ef7530;
  border-radius: 4px;
  cursor: pointer;
}
.prolong_dialog .form-control__datepicker > div {
  background-color: unset !important;
  width: 100%;
}
.prolong_dialog .form-control > div > div {
  background: unset;
}
