.project_card__link {
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  line-height: 143%;
}
.project_card__link i {
  padding-left: 6px;
}
.project_card__tooltip {
  margin-left: -20px !important;
  background-color: #ffffff !important;
  box-shadow: 0 1px 14px rgba(71, 71, 71, 0.25);
  border-radius: 4px;
}
.project_card__tooltip a {
  font-size: 15px;
}

.swiper {
  padding: 5px !important;
  margin: unset !important;
}
.active_project__title {
  font-size: 16px !important;
  font-weight: 700 !important;
  letter-spacing: 0.17px !important;
  line-height: 143% !important;
  padding-bottom: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
