.feedback_page__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 36px;
}
.feedback_page__content h2 {
  margin: 0;
}
.feedback_page__content h4 {
  font-weight: 500;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #797979;
}
.feedback_page__content__link_field span {
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.15px;
}
.feedback_page__content p {
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #222222;
}
.feedback_page__checklist {
  background: #f1f1f1;
  height: 100%;
  padding: 24px 15px 0 24px;
}
.feedback_page__checklist ol {
  padding: 0 10px 0 20px;
}
.feedback_page__checklist .checklist_item {
  width: 100% !important;
}
.feedback_page__checklist .checklist_item--input-number input {
  min-width: 35px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  padding: 0 0 0 6px !important;
}
.feedback_page__risk {
  display: flex;
  flex-direction: column;
}
.feedback_page__risk h4 {
  margin-bottom: 10px;
}
.feedback_page__risk span {
  border-radius: 4px;
  padding: 6px;
  width: fit-content;
  color: #ffffff;
}
.risk_danger {
  background-color: #f2430c;
}
.risk_warning {
  background-color: #fcab40;
}
.risk_no_riks {
  background-color: #9b9b9b;
}
.risk_resolved {
  background-color: #d1d1d1;
}

.loader_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
}
.loader_wrapper span {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.resolve_feedback_dialog .form-control__datepicker > div {
  background-color: unset !important;
  width: 100%;
}
.resolve_feedback_dialog .form-control > div > div {
  background: unset;
}
