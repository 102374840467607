.multi-value-suggestbox__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 50px;
}

.multi-value-suggestbox__label {
  display: flex;
  flex-direction: row;
  height: 12px;
}

.multi-value-suggestbox__label > span {
  width: fit-content;
  padding: 0;
  font-size: 12px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1;
}

.multi-box-wrapper {
  width: 100%;
  margin-right: 50px;
  margin-bottom: 8px;
}

.multi-box-wrapper .multi-value-suggestbox__container {
  display: flex;
  width: 100%;
  padding-top: 0;
}

.multi-value-suggestbox__indicators
  .multi-value-suggestbox__loading-indicator
  span {
  font-size: 5px;
  background-color: hsl(16, 88%, 59%);
}

/* style for location select in Profile Page */
.location__option svg {
  width: 30px;
  min-width: 30px;
}
.location__option {
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.location__option:hover {
  background-color: #f3f3f3;
}
.location__option span {
  margin-left: 10px;
}
