.c-list__item-cont {
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding-left: 10px;
  flex-shrink: 0;
  font-size: 14px;
  color: #4b4b4b;
  min-height: 56px;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid #d1d1d1;
  border-top: none;
  background: #fff;
}

.c-list__item-cont.dropdown_header {
  border: none;
  border-top: 1px solid #d1d1d1;
}

.c-list__item-cont:hover {
  background: #fff3e5 !important;
}

.read-more-row {
  padding: 16px;
  background: #f1f1f1;
  border: 1px solid #d1d1d1;
  border-top: none;
  color: #4b4b4b;
}

.read-more-row p {
  max-width: 900px;
  width: 100%;
  margin: 0;
}

.cell-img-toggle-button__cont {
  display: flex;
  justify-content: space-around;
  width: 65px;
}

.cell-img-toggle-button__cont div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-list__item-column {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-basis: 150px;
  padding: 0 10px;
  overflow: hidden;
  height: 40px;
  white-space: pre-wrap;
  color: black;
}

.c-list__header-col.current {
  background: rgba(0, 0, 0, 0.1);
}

.c-list__item-column.grouped-col .email-col {
  opacity: 0;
}
.c-list__item-column.grouped-col:hover .email-col {
  opacity: 1;
}

.array_column {
  display: block;
  display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.c-list__item-column a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 14px;
  max-height: 28px;
}

.c-list__item-column svg {
  transition: 0.2s;
}

.c-list-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  margin: 0 10px 0 0 !important;
  width: 24px;
  height: 24px;
  color: white;
  font-size: 12px;
}

.c-list-logo--badge {
  font-size: 12px;
  color: #ffffff;
}

.opened-svg {
  transform: rotate(90deg);
}

.c-list-item__options-column__container {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
}

.c-list-item__options-column__button-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.c-list-item__options-column__option {
  display: flex;
  align-items: center;
  width: 200px;
  height: 50px;
  background: white;
  transition: 0.3s;
  padding: 0 16px;
}

.c-list-item__options-column__option.disabled {
  pointer-events: none;
}

.c-list-item__options-column__option.disabled i,
.c-list-item__options-column__option.disabled span {
  opacity: 0.5;
}

.c-list-item__options-column__option i {
  margin-right: 32px;
}

.c-list-item__options-column__option:hover {
  background: #dfdfdf;
  cursor: pointer;
}

.col-span {
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto 0;
}

.check_role {
  padding: 0 !important;
}

.c-skill__item div {
  width: 100%;
}

.cell_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.cell_avatar-badge > div {
  width: 40px;
  height: 40px;
}

.c-list__options_overflow_unset {
  overflow: unset;
}
