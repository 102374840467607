.c-list__status-bench-invested {
  min-width: 80px;
  background-color: rgb(235, 170, 22) !important;
  color: #fff !important;
}
.c-list__status-bench-billable {
  min-width: 80px;
  background-color: #1cb18e !important;
  color: #fff !important;
}
.c-list__status-bench-dismissed {
  min-width: 80px;
  background-color: #9b9b9b !important;
  color: #fff !important;
}
.c-list__status-bench-internal {
  min-width: 80px;
  background-color: #94cf78 !important;
  color: #fff !important;
}
.c-list__status-bench-non_prod {
  min-width: 80px;
  background-color: rgb(192, 192, 187) !important;
  color: #fff !important;
}
