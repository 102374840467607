.dialog_title {
  padding-bottom: 0;
}

.dialog_content {
  border: none;
  padding: 8px 24px 24px;
}

.dialog_actions {
  padding: 24px;
  justify-content: flex-start;
}

.dialog_actions .submit_btn {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 6px 16px;
  color: white;
  background: #ef7530;
}

.dialog_actions .submit_btn:hover {
  background: #ef7530;
}

.dialog_actions .cancel_btn {
  font-weight: 400;
  padding: 6px 16px;
  color: black;
}
