.next:not(.cancel) {
  color: white;
  background: #f26939;
}

.next:not(.cancel):hover {
  background: #d95e33;
}

.dialog-width div div {
  width: 500px;
  overflow-y: visible;
}

.dialog-width .MuiPaper-root {
  min-height: 190px;
}

.show-dialog-title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: normal;
  text-transform: uppercase;
}

.show-dialog-content {
  position: absolute;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #000000;
  margin-left: 10px;
  margin-right: 5px;
}

/* todo: move to dialog*/
