.search-box {
  padding: 20px 20px 10px;
  z-index: 2;
  overflow: visible !important;
  margin-bottom: 11px;
}

.search-box_footer {
  display: flex;
  justify-content: flex-end;
}

.button-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.button-panel.employee_list_panel {
  display: block;
}

.button-panel.employee_list_panel .button-panel_left {
  justify-content: space-between;
}

.button-panel_left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-constructor-panel {
  display: flex;
  justify-content: flex-start;
}

.filter-constructor-panel_box {
  width: 50%;
  margin-right: 25px;
}

.filter-constructor-panel_box:last-child {
  margin-right: 0;
}

.search-row {
  display: grid;
  grid-auto-columns: 1fr;
  align-items: end;
  justify-items: center;
  grid-gap: 10px;
  margin-bottom: 12px;
}

.search-row_filter {
  grid-template-columns: 1fr 2fr 4fr 0.5fr;
}

.search-row_skill {
  grid-template-columns: 1fr 3fr 1fr 4fr 0.5fr;
}

.search-row > div {
  width: 100%;
}
