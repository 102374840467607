.skill__list-wrapper {
  padding: 0 20px 20px 36px;
}
.skills__list-container__hided {
  display: none !important;
}
.skill__list-category__header {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
  display: flex;
  align-items: center;
}
.skill__list-category__header i {
  cursor: pointer;
  padding: 10px;
}
.skill__list-category-icon__rotate {
  transform: rotate(90deg);
}
.skills__list-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 10px;
}

/* select styles */
.skills-level-select_cell-list {
  width: 150px;
}
.skills-level-select_cell-list fieldset {
  border: unset;
}
.skills-level-select_cell-list > div {
  font-family: 'Roboto';
  font-size: 14px;
}
.skills-level-select_cell-list > div > div {
  padding: unset;
}

.skills-level-select_cell-list .placeholder--is-disabled > div {
  background: unset !important;
  color: black;
}
.skills-level-select_cell-list .placeholder--is-disabled svg {
  display: none;
}
.skills-level-select_cell-list .Mui-disabled {
  color: inherit !important;
  -webkit-text-fill-color: inherit !important;
}
/*  */
.skill__list__align .c-list__header {
  flex-flow: row wrap;
}
.skill__list__align .c-list__item-cont {
  flex-flow: row wrap !important;
}
.skill__list__align .action-col {
  margin-left: auto;
}
.skill__list__align .cell-img-toggle-button__cont {
  margin-left: auto;
}

.skill__wrapper_paddings .toolbar-container {
  padding: 0 20px 0 36px;
}
.skill__wrapper_paddings .profile_info hr {
  margin: 27px 0 0 0;
}

.skill_items__empty {
  margin: 0 32px 0 36px;
}

.add_skill_modal > div:nth-child(3) {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 5%;
}

.add_skill_modal .grouped-select_wrapper > div {
  width: 100%;
  max-height: 100% !important;
}
