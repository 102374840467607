.checklist_item {
  width: 400px;
}

.checklist_item-name {
  margin-left: 5px;
  padding-top: 4px;
}

.checklist_item .configuration-option {
  cursor: auto;
}

.checklist_item .configuration-option:hover {
  background-color: unset;
}

.checklist_item--input-number {
  width: 4rem;
  justify-content: flex-start;
}

.checklist_item--input-number input {
  padding: 0 0.5rem;
}
