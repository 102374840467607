.skills-questionnaire {
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.skills-questionnaire__actions {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  padding: 10px 37px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.secondary__btn {
  color: #4b4b4b;
  border: 1px solid #9b9b9b;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  background-color: transparent;
  margin-right: 16px;
  padding: 6px 16px;
  text-transform: uppercase;
  cursor: pointer;
}

.skills-questionnaire .button__main {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
}

.skills__heading {
  font-weight: 400;
  font-size: 24px;
  color: #222222;
  margin: 0;
}

.cancel__btn {
  color: #797979;
  border: none;
  font-size: 14px;
  background-color: transparent;
  margin-right: 16px;
  padding: 4px 0;
  cursor: pointer;
}

.skills-questionnaire__list {
  width: calc(100% - 500px);
}

.skills-questionnaire__categories__list {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #4b4b4b;
  width: 450px;
}

.skills-questionnaire__categories__list li {
  margin-bottom: 20px;
  cursor: pointer;
  transition: color 0.3s ease-out;
}

.skills-questionnaire__categories__list li.active {
  font-weight: 600;
  color: #ef7530;
}

.skill-questionnaire__option:last-child,
.skills-questionnaire__categories__list li:last-child {
  margin-bottom: 27px;
}

.skills-questionnaire__list,
.skills-questionnaire__categories__list {
  overflow-y: scroll;
  height: calc(100% - 160px);
}

.skills-questionnaire__list h6 {
  font-weight: 500;
  font-size: 20px;
  color: #222222;
  margin: 22px 0 26px;
}

.skill-questionnaire__option--title {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #000000;
}

.skill-questionnaire__option--label > span:last-child {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
}
