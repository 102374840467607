.feedbacks_list__wrapper {
  padding: 24px 36px 0 36px;
  height: 100%;
}
.table_toolbar__input input {
  width: 600px;
}
.table_toolbar__btn {
  margin-left: 24px !important;
}
.table_toolbar__btn svg {
  color: #ef7530;
}
.toolbar_wrapper {
  width: 720px;
  display: flex;
  align-items: center;
  padding: 14px;
  justify-content: space-between;
}
.button__main:disabled {
  opacity: 0.7;
}
