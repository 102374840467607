.form h1 {
  font-size: 48px;
  text-transform: uppercase;
  font-weight: 300;
}

.form h2 {
  font-weight: normal;
  line-height: 25px;
  font-size: 14px;
  color: #4b4b4b;
  margin: 0;
}

.form h3 {
  font-style: italic;
  font-weight: normal;
  line-height: 25px;
  font-size: 13px;
  color: #f26939;
}

.form input {
  border: 1px solid #dfdfdf;
  margin: 9px 0 9px 0;
  height: 48px;
  font-weight: 300;
  font-size: 18px;
  color: #4b4b4b;
  padding-left: 20px;
}

.form input:hover {
  margin: 9px 0 9px 0 !important;
}

.input div::before {
  border: none;
}

.input div::after {
  border: none;
}

.input div:hover::before {
  border: none !important;
}

.clearInput {
  position: absolute;
  top: 40%;
  right: 8%;
  transform: scale(0.6);
  cursor: pointer;
}

.error {
  font-style: italic;
  color: #f26939;
  min-height: 17px;
  margin-bottom: 10px;
}
