.toolbar-container {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  padding: 20px;
}

.toolbar-container__main-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.projects-amount {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 85px;
  padding: 25px 0 15px 0;
}

.list-page-toolbar .toolbar {
  height: auto;
  padding: 0;
  margin-bottom: 15px;
}

.details-toolbar {
  height: 60px;
  padding: 15px 0;
}

.left-toolbar-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-toolbar-status-checkbox {
  padding-left: 20px;
}

.search-cont {
  position: relative;
}

.search-field + svg {
  position: absolute;
  top: 14%;
  right: 1%;
  transition: 0.3s;
}

.search-field + .list-tool-bar__input-svg--position {
  position: absolute;
  top: 14%;
  right: 1%;
  transition: 0.3s;
}

.clear-search {
  cursor: pointer;
}

.search-cont svg path {
  transition: 0.3s;
}

.search-field {
  width: 450px;
  outline: none;
  border: 1px solid #dfdfdf;
  padding: 8px 40px 8px 15px;
  font-size: 14px;
  transition: 0.3s;
}

.search-field--wide {
  width: 100%;
}

.search-field.with-btn {
  padding-right: 90px;
}

.search-field:focus {
  border: 1px solid #f26939;
}

.search-field::-webkit-input-placeholder {
  color: #b0b0b0;
}

.search-field::-moz-placeholder {
  color: #b0b0b0;
}

.search-field:-ms-input-placeholder {
  color: #b0b0b0;
}

.search-field:-moz-placeholder {
  color: #b0b0b0;
}

.search-actions {
  position: absolute;
  top: 2px;
  right: 1px;
  bottom: 1px;
}

.search-action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: auto !important;
  padding: 0 8px !important;
  border-radius: 0 !important;
  transition: none !important;
}

.search-action:not(.disabled) {
  cursor: pointer;
}

.search-action .MuiTouchRipple-root {
  display: none;
}

.search-action:not(.disabled):hover,
.search-action:not(.disabled):focus {
  background-color: transparent !important;
}

.search-action:active {
  opacity: 0.75;
}

.search-action:not(.disabled):hover svg path,
.search-action:not(.disabled):focus svg path {
  fill: #f26939;
}

.search-action-search {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 8px;
  background-color: #f4f4f4;
  transition: 0.3s;
}

.search-action-search:not(.disabled):hover,
.search-action-search:not(.disabled):focus {
  background-color: #fef0eb !important;
}

.search-action-search svg path {
  fill: rgb(165, 165, 165);
}

.search-action-search.active-action {
  background-color: #fef0eb !important;
}

.search-action-search.active-action svg path {
  fill: #f17e55;
}

.filter-options {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  margin: 0 0 0 30px;
}

.filters:hover path {
  fill: #d95e33;
}

.configuration-option {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 35px !important;
  padding: 0 15px;
  cursor: pointer;
  user-select: none;
  margin: 0;
  transition: 0.3s;
}

.configuration-option > span {
  box-sizing: unset;
}

.configuration-option__checkbox__container {
  height: 16px;
  width: 16px;
  padding: 10px;
  position: relative;
  transition: 0.3s ease;
}

.configuration-option__checkbox__container:hover {
  background: #fff3e540;
}

.configuration-option__checkbox {
  position: absolute;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1px solid #bdbdbd;
  cursor: pointer;
}

.right-toolbar-part {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.toolbar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 20px;
  height: 100%;
  cursor: pointer;
  user-select: none;
  padding: 0 10px;
  border: 1px solid transparent;
  transition: 0.3s;
}

.toolbar-button svg {
  margin: 0 10px 0 0;
  transition: 0.3s;
}

.toolbar-button:hover {
  border-color: #f26939;
}

.toolbar-button-disabled svg {
  margin: 0 10px 0 0;
  transition: 0.3s;
}

.filters-menu {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  background: #f8f8f8;
  transition: 0.3s;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
  margin-top: 1px;
}

.filters-menu::after {
  content: '';
  display: block;
  position: absolute;
  border-bottom: 10px solid #f8f8f8;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  left: 482px;
  top: -10px;
  filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.1));
}

.filter-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.filter-buttons {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.filter-buttons div {
  display: flex;
  justify-content: center;
}

.save-btn {
  margin-left: 20px !important;
}

.filter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 35px;
  font-size: 16px;
  margin: 20px 20px 20px 0;
  transition: 0.3s;
  cursor: pointer;
  user-select: none;
}

.apply {
  color: white;
  background: #f26939 !important;
}

.apply:hover {
  background: #d95e33 !important;
}

.apply[disabled] {
  background: #d57655 !important;
}

.reset:hover {
  background: #dfdfdf;
}

.chosen-filters {
  position: absolute;
  top: -3px;
  right: -3px;
}

.list-toolbar__view-tabs {
  margin-top: 5px;
  padding-left: 20px;
  height: 30px !important;
}

.list-toolbar__view-tabs div div {
  height: 25px;
  overflow: hidden;
}

.list-toolbar__view-tabs div div div span {
  width: 100px;
  min-width: 100px;
}

.list-toolbar__view-tabs div div div button {
  padding: 0 !important;
  min-height: 10px;
  width: 100px;
  min-width: 100px;
}

.list-toolbar__view-tabs div div .MuiTabs-indicator {
  background-color: #f26939 !important;
}

.step__form {
  margin: 0;
  min-height: 46px;
  justify-content: flex-end;
}

.step__input > label > span,
.step__form > label > span,
.step__date-picker > label > span {
  color: orangered !important;
}

.step__input > div,
.step__select > div,
.step__date-picker > div {
  width: 100%;
  height: 35px;
  min-height: 35px;
}

.c-SOW-edit__description > label > span,
.step__form > label > span,
.step__date-picker > label > span {
  color: orangered !important;
}

.step__description > label > span,
.step__form > label > span,
.step__date-picker > label > span {
  color: orangered !important;
}

.step__form > div > div:before,
.step__form > div > div:after {
  content: none;
}

.step__form input {
  width: 100%;
  height: 100%;
  min-height: 38px;
  padding: 0 40px 0 10px;
}

.step__form .date-svg {
  position: absolute;
  cursor: pointer;
  top: 45%;
  right: 16%;
}

.step__form > div > div {
  background: #f2f2f2;
  border: 1px solid #dfdfdf;
  font-size: 14px;
}

.empty-settings-search-block {
  width: 548px;
  height: 34px;
}
