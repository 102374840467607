.fieldTitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #797979;
  margin-bottom: 8px;
}

.fieldValue {
  font-weight: 400;
  font-size: 15px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #222222;
  margin-top: 0;
}

.fieldValue a {
  font-size: 14px;
  line-height: 143%;
  color: #1976d2;
  text-decoration: none;
}
