.empolyee_profile_wrapper {
  margin: 0 36px;
}

.profile-block {
  padding: 24px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  margin: 6px 24px 24px 0;
}

.profile-block__avatar {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.profile-block__default-avatar > div {
  width: 90px;
  height: 90px;
  font-size: 24px;
}

.profile-block__name {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin-right: 10px;
}

.profile-block__position {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #4b4b4b;
}

.profile_block__delimiter {
  padding-bottom: 24px;
  padding-top: 24px;
  border-top: 1px solid #d1d1d1;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  position: relative;
}

.profile_block__delimiter:first-child {
  border: none;
  padding-top: 0;
  padding-bottom: 16px;
}

.profile_block__title {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 15px;
  line-height: 143%;
  letter-spacing: 0.17px;
  text-transform: uppercase;
  color: #222222;
}

.profile_block__skill_item {
  padding: 6px 8px;
  border-radius: 16px;
  border: 1px solid #d1d1d1;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.16px;
}

.profile_block__row_title {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.17px;
  color: #222222;
}

.profile_block__contacts_item {
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.17px;
  color: #4b4b4b;
}

.button__main {
  background-color: #ef7530;
  border-radius: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: #ffffff;
  margin: 6px 10px;
  outline: unset;
  border: unset;
  padding: 6px 12px;
  cursor: pointer;
}

.button__secondary {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  align-items: center;
  letter-spacing: 0.46px;
  text-transform: uppercase;
  color: #797979;
  outline: unset;
  border: unset;
  padding: 6px 12px;
  cursor: pointer;
}

.profile-block__input {
  padding: 6px 6px 6px 0 !important;
}

.profile-block__input input {
  padding: 5px;
}

.profile-block__input_width100px {
  padding: 6px 6px 6px 0 !important;
}

.profile-block__input_width100px input {
  padding: 5px;
  width: 100px;
}

.profile_block__skill_item__blue-back {
  background: #c9e6f4;
}
.profile_block__main_tech_item__green-back {
  background: #94cf78;
}

.profile_block__action-wrap {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.hide_header {
  padding-top: unset;
}

.relative-list {
  position: relative;
  padding-bottom: 20px;
}

.profile_block__datepicker {
  max-width: 170px;
}

.profile_block__datepicker input {
  padding: 5px;
}

.profile-block__multibox {
  padding: 5px;
  width: 200px;
  cursor: pointer;
}

.profile-block__multibox > div > div {
  background-color: unset !important;
  border-radius: 5px !important;
  border: 1px solid #d1d1d1 !important;
}

.profile-block__location-input {
  height: 50px;
  padding: 6px 6px 6px 6px !important;
}

.profile-block__location-input input {
  padding: 7px;
}

.profile_block__members_avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.profile_block__members_default_avatar {
  width: 32px;
  height: 32px;
}

.profile_block__members_default_avatar > div {
  width: 32px;
  height: 32px;
}

.manager-option__avatar {
  border-radius: 50%;
  margin: 12px;
}

.manager-option__avatar,
.manager-option__avatar > div {
  width: 32px;
  height: 32px;
}

.manager_selectbox--input > div {
  padding: 0 0 0 8px !important;
}

.profile_probation__in_progress {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #4b4b4b !important;
  background-color: #c9e6f4 !important;
  /*margin-right: 8px;*/
}
.profile_probation__failed {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #ffffff !important;
  background-color: rgba(235, 82, 48, 0.5) !important;
  /*margin-right: 8px;*/
}
.profile_vacation_label {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #4b4b4b !important;
  background-color: #fff0bb !important;
  /*margin-right: 8px;*/
}
