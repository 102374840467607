.form-control {
  min-height: 46px;
  justify-content: flex-end;
}

.form-control input {
  width: 100%;
  height: 100%;
  min-height: 38px;
  padding: 0 40px 0 10px;
  border: 0 !important;
}

.form-control fieldset {
  border: 0 !important;
}

.form-control > div > div {
  /* background: #f2f2f2; */
  border: 1px solid #dfdfdf;
  border-radius: 0;
  font-size: 14px;
}

.form-control > div > div:before,
.form-control > div > div:after {
  content: none;
}

.form-control__input__error > .MuiInputBase-root {
  border: 0.5px solid red !important;
}

.form-control__file-input__button {
  margin-right: 8px;
  min-height: 38px;
  padding: 0 40px 0 10px;
  border: 0;
  background: #f2f2f2;
}

.form-control__file-input__button:not([disabled]):hover {
  background: #cfcfcf;
}
.form-control__datepicker > div {
  background-color: #f2f2f2;
}
.form-control__datepicker input {
  padding: 8px 14px;
}
.form-control__datepicker fieldset {
  border-radius: unset;
  border-color: #dfdfdf;
}

.file__field--holder.dropzone {
  border: 1px dashed #9b9b9b;
  border-radius: 4px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file__field--holder.dropzone.error {
  border-color: red;
}

.file__field--holder.dropzone label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.file__field--holder.dropzone label i {
  margin-bottom: 8px;
}

.file__field--holder.dropzone label p {
  margin: 0;
}

.file__field--holder.dropzone label span {
  color: #1976d2;
  cursor: pointer;
}

.file__field--holder.dropzone.active {
  border: 1px solid rgb(3, 179, 3);
}

.form-control__file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.form-control__file-input + .form-control__file-input__button {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
}
.form-control__file-input + .form-control__file-input__button.icon:before {
  content: '\f093';
}
.form-control__file-input
  + .form-control__file-input__button.has-file.icon:before {
  content: '\f00c';
  color: #5aac7b;
}

.redux_form-filename-cdp_edit {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14),
    0 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.redux_form-filename-cdp_edit .cv__file-name-width {
  display: flex;
  align-items: center;
}

.redux_form-filename-cdp_edit .cv__file-name-width i {
  margin-right: 16px;
}

.redux_form-filename-cdp_edit .cv__file-name-width div {
  display: flex;
  flex-direction: column;
}

.redux_form-filename-cdp_edit .cv__file-name-width div .file_name {
  max-width: 350px;
  word-break: break-all;
}

.redux_form-filename-cdp_edit .cv__file-name-width div .file_size {
  font-size: 14px;
  color: #797979;
}

.redux_form-filename-cdp_edit button {
  background: none;
  border: none;
  cursor: pointer;
}
.form_field__label {
  display: flex;
  align-items: center;
  color: #828282;
  font-size: 12px;
  font-weight: bold;
  height: 15px;
  padding-bottom: 8px;
}
