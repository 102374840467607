.feedback_create__title {
  font-weight: 400;
  font-size: 24px;
  line-height: 133.4%;
  color: #222222;
}
.feedback_create__header {
  padding: 23px 36px;
  border-bottom: 1px solid #d1d1d1;
}
.feedback_create__form__padding {
  padding-top: 25px;
}
.feedback_create__form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 50px);
  padding: 23px 36px;
}
.feedback_create__form > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: #222222;
}
.feedback_create__form .form_field__label {
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #797979;
  font-weight: 400;
}

.feedback_create__form .multi-value-suggestbox__label {
  height: unset;
}
.feedback_create__form .form-control__datepicker > div {
  background-color: unset !important;
  width: 100%;
}

.feedback_create__checklist {
  background: #f1f1f1;
  height: 100%;
  padding: 24px 15px 0 24px;
}
.feedback_create__checklist .checklist_item {
  width: 100%;
}
.feedback_create__checklist .checklist_item--input-number input {
  min-width: 35px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  padding: 0 0 0 6px !important;
}
.feedback_create__checklist ol {
  padding: 0 10px 0 20px;
}
.feedback_create__checklist .step__form--event-big-block {
  background: #ffffff;
}
.feedback_create__checklist .step__form--event-big-block > div {
  padding: 6px;
}
.feedback_create__visibility_help {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: #4b4b4b;
  background: #e6f7ff;
  display: flex;
  height: 100%;
  align-items: center;
  padding-right: 17px;
}
.feedback_create__visibility_help i {
  padding: 0 12px 0 17px;
}
