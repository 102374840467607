.doc_viewer__wrapper {
  width: 500px;
  height: 300px;
}
.doc_viewer__progress {
  display: flex;
  justify-content: center;
  align-items: center;
}
.doc_viewer__wrapper #pdf-controls {
  display: none;
}
.doc_viewer__wrapper #pdf-page-wrapper {
  margin: 0;
}
.doc_viewer__docx {
  font-size: 10px;
  width: 80%;
  margin: 10px auto;
}
.modal_viewer__wrapper .MuiPaper-root {
  min-width: 700px;
  min-height: 600px;
}
.modal_viewer__wrapper #pdf-controls {
  display: none;
}
.modal_viewer__wrapper #pdf-page-wrapper {
  margin: 0;
}
