.error_page__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.error_page__wrapper h1 {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 72px;
  line-height: 133.4%;
  margin: 0;
}
.error_page__wrapper p {
  width: 426px;
  height: 50px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 133.4%;
  color: #797979;
  text-align: center;
}
.error_page__wrapper button {
  width: 220px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
}
