.c-tabs {
  display: flex;
  justify-content: space-between;
}

.c-tabs__title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  height: 100%;
}

.c-tabs__title:hover {
  cursor: pointer;
}

.c-tabs__title__text {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(168, 168, 168);
}

.c-tabs__title--selected {
  border-bottom: 2px solid #f26939;
}

.c-tabs__title--selected .c-tabs__title__text {
  font-weight: bold;
  line-height: 20px;
  color: #222222;
}
